<template>
  <div>
    <div class="header">
      <el-button type="primary" @click="$router.push('/information')"> 返回 </el-button>
    </div>

    <div class="main">
      <h4>{{ title }}</h4>
      <div v-html="content"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "",
      content: "",
    };
  },
  created() {
    this.title = localStorage.getItem("title") || "";
    this.content = localStorage.getItem("content") || "";
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.header {
  background: none;
  margin-bottom: 24px;
}
.main {
  background: #fff;
  padding: 20px;
  h4 {
    text-align: center;
  }
}
</style>
